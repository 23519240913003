import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import truncate from "truncate";

import Box from "components/box";
import RichText from "components/rich-text";
import Layout from "components/layout";
import SEO from "components/seo";
import LogAttributes from "components/log-attributes";
import { getDiscussUrl } from "lib";

import "./style.css";

const WikiSingle = props => {
  const { data, location } = props;
  const { markdownRemark, site } = data;
  const {
    siteMetadata: { siteUrl },
  } = site;
  const { frontmatter, html, rawMarkdownBody: text } = markdownRemark;
  const { title, date } = frontmatter;
  const image = null;

  const truncateText = truncate(text, 140);

  const discussUrl = getDiscussUrl({
    siteUrl,
    pathName: location.pathname,
  });

  return (
    <Layout className="log-single">
      <SEO
        description={truncateText}
        title={title}
        image={image}
        pathName={location.pathName}
      />
      <div className="log-cover">
        <div className="log-image-wrapper">
          {image ? <img src={image} alt={title} /> : null}
        </div>
        <div className="log-info wrapper">
          <Box>
            <h1>{title}</h1>
            <LogAttributes text={text} date={date} />
          </Box>
        </div>
      </div>
      <div className="wrapper">
        <RichText text={text} limit />
        <div className="log-extras rich-text">
          <a
            className="log-discuss"
            href={discussUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Comment on Twitter
          </a>
        </div>
      </div>
    </Layout>
  );
};

WikiSingle.propTypes = {
  location: PropTypes.shape({
    pathName: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({}),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string,
      }).isRequired,
    }),
  }).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      rawMarkdownBody
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export default WikiSingle;
